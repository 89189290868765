import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-descontos',
  templateUrl: './descontos.component.html',
  styleUrls: ['./descontos.component.scss'],
})

export class DescontosComponent implements OnInit {

  user_id;
  user_name;

  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  
  pagina = 1;
  beneficios:any = [];

  modal_ativo;
  modal_banner_url;

  confirmacaoAceita: boolean = false;


  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
  
  regulamento = false;
  is_desktop = false;
  pontos = 0;
  moedas = 0;
  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService) {

  }

  ngOnInit() {

    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    /* let rota:string = this.route.snapshot.url.toString();
    if(rota.includes('desktop')){
      this.is_desktop = true;
    } */

    this.obter_dados();    
    this.obter_modal();
    this.obter_beneficios();
  }
  
  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = parseInt(data.pontos);
      this.moedas = parseInt(data.moedas);
    });
  }

  obter_beneficios() {
    this.http.get(environment.API_URL.concat('beneficios/obter_beneficios')).subscribe((data: any) => {
      const beneficios = data;
      
      const meio = Math.floor(beneficios.length / 2);

      this.beneficios['mostrar'] = data.slice(0, meio);
      this.beneficios['pag1'] = data.slice(0, meio);
      this.beneficios['pag2'] = data.slice(meio);


    })
  }

  set_pagina(p){
    this.pagina = p;
    this.beneficios['mostrar'] = this.beneficios['pag'+p];
  }

  redirecionarComConfirmacao(event: Event, url: string, beneficioId: number) {
    if (!window.confirm('Tem certeza de que deseja ir para essa loja?')) {
      event.preventDefault(); // Evitar o redirecionamento
    } else {
      this.registrar_acesso(beneficioId);
    }
  }
  

  
  registrar_acesso(beneficio) {
        // (<any>window).dataLayer.push({
        //     'event': 'clubeMDOEvents',
        //     'category': beneficio.nome,
        //     'action': 'clique'
        // });

        this.http.post(environment.API_URL.concat('beneficios/registrar_acesso'),
            { 'beneficio': beneficio }).subscribe((data: any) => {
                //console.log('registrado');
            });
  }

  obter_modal() {
    this.http.post<any>(environment.API_URL.concat('acoes/modal'), {
      id_usuario: this.user_id
    }).subscribe((data: any) => {
      if (data.status == 1) {

        if (localStorage.getItem('viumodal_' + data.modal.titulo) == '01') {
          return;
        }
        this.modal_ativo = data.modal;
        this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal_ativo.banner);
        setTimeout(() => {
          this.abrir_modal_acao();
        }, 2000);

        localStorage.setItem("viumodal_" + data.modal.titulo, '01');

      }

    });
  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }

}


