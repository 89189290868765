import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-ganhadores',
  templateUrl: './ganhadores.component.html',
  styleUrls: ['./ganhadores.component.scss'],
})
export class GanhadoresComponent implements OnInit {

  user_id;
  user_name;
  pontos;
  moedas;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    this.obter_dados();
  }

    obter_dados() {
      this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
        this.pontos = parseInt(data.pontos);
        this.moedas = parseInt(data.moedas);
      });
    }

}
