import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';

interface ModalNpsResponse {
  status: number;
  showModal: boolean;
  modal?: {
    id: number;
    titulo: string;
    dias_exibicao: string;
    ativo: boolean;
  };
  dias?: number[];
  isActive?: boolean;
}

@Component({
  selector: 'app-nps-modal',
  templateUrl: './nps-modal.component.html',
  styleUrls: ['./nps-modal.component.scss']
})
export class NpsModalComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Output() modalClosed = new EventEmitter<void>();

  loading: boolean = false;
  starImages: string[] = Array(5).fill('assets/estrela-vazada.svg');
  userId: string | null;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.userId = this.storageService.getItem('userId');
  }

  ngOnInit() {
    if (this.userId) {
      this.verificarVisibilidade();
    }
  }

  private verificarVisibilidade(): void {
    this.http.get<ModalNpsResponse>(`${environment.API_URL}modal_nps/check_visibility/${this.userId}`)
      .subscribe({
        next: (response) => {
          if (response.status === 1 && response.showModal) {
            this.isVisible = true;
          }
        },
        error: (error) => {
          console.error('Erro ao verificar visibilidade do modal:', error);
        }
      });
  }

  selecionarNps(index: number): void {
    if (this.loading) return; // Evita múltiplos cliques durante o carregamento
    
    this.loading = true;
    this.starImages = this.starImages.map((_, i) => 
      i <= index ? 'assets/estrela-preenchida.svg' : 'assets/estrela-vazada.svg'
    );

    // Envia o voto imediatamente
    this.http.post(`${environment.API_URL}modal_nps/votar`, {
      id_participante: this.userId,
      nps: index + 1
    }).subscribe({
      next: (response: any) => {
        if (response.status === 1) {
          this.storageService.setItem('votou_nps', (index + 1).toString());
          this.storageService.setItem('ultima_votacao_nps', new Date().toISOString());
          this.fecharModal();
        } else {
          alert(response.message || 'Erro ao enviar avaliação');
        }
      },
      error: (error) => {
        console.error('Erro ao enviar avaliação:', error);
        alert('Erro ao enviar avaliação. Tente novamente.');
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  fecharModal(): void {
    this.isVisible = false;
    this.modalClosed.emit();
  }
}