import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Platform } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {

  safeURL;
  parceiros;

  foto_src: string;
  foto_padrao = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  user_id;
  user_name;

  pontos;
  moedas;
  loaded_data = false;

  leiloes = [];
  temLeilao = false;

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  };

  pontos_ganhos;
  moedas_ganhos;
  modal_ganhou = false;

  modal;
  modal_ativo;
  modal_banner_url;

  modalAberto: boolean = false;
  modalAtencao: boolean = false;
  modalExpirado: boolean = false;
  modalParcela: boolean = false;
  modalRegras: boolean = false;

  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };

  sliderOptionsCards = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination-cards',
    },
  };

  sliderOptionsShares = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
    },
    pagination: {
      el: '.swiper-pagination-shares',
    },
  };

  has_quiz: boolean = false;
  etapa_quiz = 1;

  quiz: any;
  perguntas: any;
  pergunta_atual = 0;
  respostas: any = [];
  resposta_correta: string;
  acertou: string;

  has_video: boolean = false;
  video: any;

  drive: any;


  propaganda;
  regulamento = false;
  loading = false;
  modal_pwa = false;
  modal_pwa_ios = false;
  mostrar_pwa;
  tentativas = 1;

  showNpsModal: boolean = false;
  modalConfig: any;

  deferredPrompt: any;

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;

  constructor(
    private renderer: Renderer2, 
    private route: ActivatedRoute, 
    private router: Router, 
    private modalService: NgbModal,
    private sanitizer: DomSanitizer, 
    private http: HttpClient, 
    private loader: NgxUiLoaderService, 
    private platform: Platform,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.user_id = this.storageService.getItem('userId');
    this.user_name = this.storageService.getItem('userName');
  
    if (!this.user_id) {
      this.router.navigate(['/login']);
    }
  
    const foto_src = this.storageService.getItem('foto_src');
    this.foto_src = foto_src ? foto_src : this.foto_padrao;
  

    this.obter_dados();

    // Inicializa todas as funções com Promise para garantir que sejam resolvidas corretamente
    setTimeout(() => {
      Promise.all([
        this.obter_modal(),
        this.obter_video(),
        this.obter_quiz(),
        this.obter_drive(),
        this.obter_leiloes(),
        this.enviarUtmSource()
      ]).catch(err => console.error('Erro ao carregar dados iniciais:', err));
    }, 1500);
  
    // Capturando âncora da URL
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.scrollToSection(fragment);
      }
    });
    
  
    // Capturando parâmetros da URL
    this.route.queryParams.subscribe(params => {
      const section = params['section'];
      if (section === 'quiz') {
        this.scrollToSection('quiz');
      }
    });
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event;
  }

  handleModalClose(): void {
    this.showNpsModal = false;
  }

  obter_pwa() {
    const isInStandaloneMode = () =>
      window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  
    const isIOS = this.platform.is('ios');
    const respostaAnterior = this.storageService.getItem('resposta_pwa');

    if (respostaAnterior === 'sim' || respostaAnterior === 'nao') {
      this.mostrar_pwa = false;
      return;
    }
  
    if (!isInStandaloneMode() && !isIOS && this.deferredPrompt) {
      this.mostrar_pwa = true;
    }

    if (isIOS) {
      // Ativar modal para iOS se necessário
    }
  }

  responder_pwa(resposta: string) {
    this.storageService.setItem('resposta_pwa', resposta);
    this.mostrar_pwa = false;
    
    if (resposta === 'sim') {
      this.add_pwa();
    }
  }

  add_pwa() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        this.deferredPrompt = null;
      });
    }
  }

  enviarUtmSource() {
    const utm_source = sessionStorage.getItem('utm_source');

    if (utm_source && this.user_id) {
      const body = {
        user_id: this.user_id,
        utm_source: utm_source
      };

      this.http.post(`${environment.API_URL}acoes_mkt/insert`, body)
        .subscribe({
          next: (response) => {
            sessionStorage.removeItem('utm_source');
          },
          error: (err) => console.error('Erro ao enviar o UTM source:', err)
        });
    }
  }

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id))
      .pipe(retry(3))  // Tenta 3 vezes
      .subscribe({
        next: (data) => {
          this.pontos = data.pontos;
          this.moedas = data.moedas;
          this.loaded_data = true;
        },
        error: (error) => {
          this.loaded_data = false;
          this.tentativas++;
          if (this.tentativas > 3) {
            alert('Não foi possível carregar os dados. Por favor, tente novamente mais tarde.');
            this.clearUserData();
          }
        }
      });
  }

  obter_video() {
    this.has_video = false;
    this.video = null;
  
    return this.http.get<any>(environment.API_URL.concat('videos/index/' + this.user_id))
      .toPromise()
      .then(data => {
        if (data.status === 1) {
          this.has_video = true;
          this.video = data.video;
        }
      })
      .catch(error => {
        console.error('Erro ao obter vídeo:', error);
      })
      .finally(() => {
        this.loader.stop();
      });
  }
  
  obter_quiz(): void {
    this.loader.start(); // Inicia o loader
  
    this.http.get<any>(`${environment.API_URL}quiz/index/${this.user_id}`).subscribe({
      next: (data) => {
        if (data.status === 1) {
          this.has_quiz = true;
          this.quiz = data.quiz;
          this.perguntas = data.perguntas;
  
          // Configura URL segura se houver material associado
          if (data.quiz.url_material) {
            this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.quiz.url_material);
          }
        }
      },
      error: (err) => {
        console.error('Erro ao obter quiz:', err);
        alert('Não foi possível carregar o quiz. Tente novamente mais tarde.');
      },
      complete: () => {
        this.loader.stop(); // Finaliza o loader
      },
    });
  }
  
  salvarResposta(perguntaIndex: number, ordem: number): void {
    this.respostas[perguntaIndex] = ordem;
  }
  
  proxima_pergunta(): void {
    if (this.respostas[this.pergunta_atual] == null) {
      alert('Por favor, selecione uma resposta.');
      return;
    }
    this.pergunta_atual++;
  }
  
  responder_quiz(): void {
    if (this.respostas.length !== this.perguntas.length) {
      alert('Por favor, responda todas as perguntas.');
      return;
    }
  
    this.loading = true;
  
    this.http.post<any>(`${environment.API_URL}quiz/responder_quiz/`, {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas,
    }).subscribe({
      next: (data) => {
        this.pontos_ganhos = data.pontos;
        this.moedas_ganhos = data.moedas;
        this.acertou = data.acertou;
        this.resposta_correta = data.resposta_correta;
  
        if (data.acertou === 'S') this.animacao_saldo();
  
        this.etapa_quiz = 2;
      },
      error: (err) => {
        console.error('Erro ao enviar respostas do quiz:', err);
        alert('Erro ao enviar respostas. Tente novamente mais tarde.');
      },
      complete: () => (this.loading = false),
    });
  }
  
  reiniciarQuiz(): void {
    this.pergunta_atual = 0;
    this.respostas = [];
    this.etapa_quiz = 1;
  }
  

  responder_enquete(): void {
    // Validação de respostas
    if (this.respostas.length !== this.perguntas.length) {
      alert('Por favor, selecione uma resposta para cada pergunta.');
      return;
    }
  
    // Configuração da requisição
    const payload = {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas,
    };
  
    this.loading = true;
  
    // Envio da requisição
    this.http.post<any>(`${environment.API_URL}quiz/responder_enquete`, payload)
      .subscribe({
        next: (data) => {
          this.pontos_ganhos = data.pontos;
          this.moedas_ganhos = data.moedas;
          this.animacao_saldo(); // Executa animação de saldo
          this.etapa_quiz = 2;   // Avança para etapa final
        },
        error: (err) => {
          console.error('Erro ao enviar respostas da enquete:', err);
          alert('Ocorreu um erro ao enviar as respostas. Tente novamente mais tarde.');
        },
        complete: () => {
          this.loading = false; // Finaliza o carregamento
        },
      });
  }
  
  obter_drive() {
    this.loader.start();
  
    return this.http.get<any>(environment.API_URL.concat('drive/index/' + this.user_id))
      .toPromise()
      .then(data => {
        if (data.status === 1) {
          this.drive = data.grupos;
        }
      })
      .catch(error => {
        console.error('Erro ao obter drive:', error);
      })
      .finally(() => {
        this.loader.stop();
      });
  }
  
  obter_leiloes() {
    return this.http.get<any>(environment.API_URL.concat('leiloes/obter_ativos'))
      .toPromise()
      .then(data => {
        this.leiloes = data.leiloes;
        this.temLeilao = this.leiloes && this.leiloes.length > 0;
      })
      .catch(error => {
        console.error('Erro ao obter leilões:', error);
        this.temLeilao = false;
      });
  }
  
  clearUserData() {
    this.storageService.removeItem('token');
    this.storageService.removeItem('userId');
    this.storageService.removeItem('foto_src');
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 500);
  }

  obter_modal() {
    return this.http.post<any>(environment.API_URL.concat('acoes/modal'), {
      id_usuario: this.user_id
    }).toPromise().then(data => {
      if (data.status === 1) {
        const key = 'viumodal_' + data.modal.titulo;
        const frequencia = data.modal.frequencia_exibicao;
        const ultimaVisualizacao = this.storageService.getItem(key);

        if (frequencia === 'unica' && ultimaVisualizacao === '01') return;
        if (frequencia === 'diaria' && ultimaVisualizacao === new Date().toLocaleDateString()) return;

        this.modal_ativo = data.modal;
        this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal_ativo.banner);
        setTimeout(() => {
          this.abrir_modal_acao();
        }, 1000);

        this.storageService.setItem(key, frequencia === 'diaria' ? new Date().toLocaleDateString() : '01');
      }
    });
  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }


  // Função para responder ao PWA no iOS
  responder_pwa_ios() {
    localStorage.setItem('resposta_pwa', 'sim');
    this.modal_pwa_ios = false;
  }

  // Função para lidar com o evento de ter assistido o vídeo
  assistiu_video(event) {
    this.pontos_ganhos = event.pontos;
    this.moedas_ganhos = event.moedas;

    this.http.post<any>(environment.API_URL.concat('videos/assistir_video'), {
      id_participante: this.user_id,
      id_video: this.video.id
    }).subscribe(data => {
      if (data.status === 1) {
        this.propaganda = false;
        this.animacao_saldo();
        this.obter_video();
      }
    });
  }

  // Função para atualizar os saldos do usuário
  atualizar_saldos($event) {
    setTimeout(() => {
      this.obter_dados();
      this.modal_ganhou = false;
    }, 3000);
  }

  animacao_saldo() {
    this.modal_ganhou = true; // Exibe o modal de saldo ganho
    setTimeout(() => {
      this.modal_ganhou = false; // Fecha o modal após 2,8 segundos
    }, 2800);
  }
  

  // As demais funções como `obter_quiz()`, `obter_leiloes()`, `assistir_video()`, `animacao_saldo()`, etc., 
  // seguem a mesma lógica de otimização aplicando Promise para melhor controle de fluxo.

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
