// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export const environment: {
  production: boolean;
  API_URL: string;
  firebase: FirebaseConfig;
} = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCBrp7iKzHikdRnQw9lbABTQFPSREYDSa0",
    authDomain: "push-zen.firebaseapp.com",
    projectId: "push-zen",
    storageBucket: "push-zen.firebasestorage.app",
    messagingSenderId: "533412646252",
    appId: "1:533412646252:web:1dae40eea7b2004d3b3749",
    measurementId: "G-7F0KY7J2XZ"
  },
  API_URL: 'https://zen.newmode.com.br/admin/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
