import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  notificationsEnabled = false;
  loading = false;
  message = '';

  constructor(private notificationService: NotificationService) {}

  async ngOnInit() {
    this.notificationsEnabled = Notification.permission === 'granted';
  }

  async toggleNotifications() {
    this.loading = true;
    this.message = '';

    try {
      if (!this.notificationsEnabled) {
        await this.notificationService.initPush();
        this.notificationsEnabled = true;
        this.message = 'Notificações ativadas com sucesso!';
      } else {
        //await this.notificationService.deleteToken();
        this.notificationsEnabled = false;
        this.message = 'Notificações desativadas com sucesso!';
      }
    } catch (error) {
      this.message = 'Erro ao configurar notificações: ' + error.message;
      console.error('Erro:', error);
    } finally {
      this.loading = false;
    }
  }



}
