    import { Component, OnInit } from '@angular/core';
    import { NotificationService } from '../../../services/notification.service';
    import { ModalController, Platform } from '@ionic/angular';

    interface SafariNavigator extends Navigator {
      standalone?: boolean;
    }
    
    @Component({
      selector: 'app-notification-modal',
      templateUrl: './notification-modal.component.html',
      styleUrls: ['./notification-modal.component.scss']
    })
    export class NotificationModalComponent implements OnInit {
      showModal: boolean = false;
      loading: boolean = false;
      error: string = '';

      isPWA = false;

      constructor(
        private notificationService: NotificationService,
        private platform: Platform
        ) {
          this.isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                      ((window.navigator as SafariNavigator).standalone === true) || 
                      document.referrer.includes('android-app://');
        }
    

      async ngOnInit() {

        console.log('É PWA?', this.isPWA);
        console.log('É iOS?', this.platform.is('ios'));

        
        if (this.isPWA && this.platform.is('ios')) {
          console.log('PWA no iOS - notificações não suportadas');
          return;
        }

        const permission = await Notification.permission;
        const modalShownInSession = sessionStorage.getItem('notificationModalShown');
        
        // Pequeno delay para garantir que tudo está carregado
        setTimeout(() => {
          if (permission !== 'granted' && !modalShownInSession) {
            this.showModal = true;
          }
        }, 1000);
      }

      async accept() {
        console.log('Iniciando accept...');
        this.loading = true;
        this.error = '';

        try {
          console.log('Iniciando accept... (PWA:', this.isPWA, ')');
          console.log('Chamando initPush...');
          const result = await this.notificationService.initPush();
          console.log('Resultado do initPush:', result);

          if(result === true) {
            console.log('Salvando no localStorage...');
            localStorage.setItem('notificationsAccepted', 'true');
            this.showModal = false;  // Aqui o modal é fechado
            console.log('Modal fechado com sucesso');
          } else{
            console.log('Erro ao ativar notificações. Tente novamente.');
            this.error = 'Erro ao ativar notificações. Tente novamente.';
          }
        } catch (err) {
          console.error('Erro completo:', err);
          this.error = 'Erro ao ativar notificações. Tente novamente.';
        } finally {
          this.loading = false;
          console.log('Loading finalizado');
        }
    }



    dismiss() {
      console.log('Dismiss chamado. showModal antes:', this.showModal);
      this.showModal = false;
      console.log('showModal depois:', this.showModal);
      sessionStorage.setItem('notificationModalShown', 'true');
    }
}